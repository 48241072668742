<template>
  <div class="space-y-4">
    <h2 class="text-xl font-semibold">Outgoing SCCF List</h2>
    <article>
      <asom-card title=" ">
        <template v-slot:header>
          <div class="flex justify-between content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">TE Bag List</h3>
            <add-outgoing-cash-bag />
          </div>
        </template>
        <p class="text-left" v-if="stationCashCollectionForm.outgoing_cashBagList.length === 0">
          No cash bag added
        </p>
        <asom-client-table
          v-else
          :filterable="false"
          :pagination="false"
          :columns="[
            'no',
            'cashBagSerialNumber',
            'remarks',
            'action'
          ]"
          :data="stationCashCollectionForm.outgoing_cashBagList"
        >
          <template v-slot:header_cashBagSerialNumber>TE Bag Serial Number</template>
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
          <template v-slot:action="slotScoped">
            <asom-button outline size="sm" variant="error" text="Remove" @click="removeCashBag(slotScoped.rowData)" />
          </template>
        </asom-client-table>
      </asom-card>
    </article>
    <article>
      <asom-card title=" ">
        <template v-slot:header>
          <div class="flex justify-between content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Cash Box List</h3>
            <add-outgoing-cash-box />
          </div>
        </template>
        <p class="text-left" v-if="stationCashCollectionForm.outgoing_cashBoxList.length === 0">
          No cash box added
        </p>
        <asom-client-table
          v-else
          :filterable="false"
          :pagination="false"
          :columns="[
            'no',
            'cashBoxSerialNumber',
            'certisBagSerialNumber',
            'isFaulty',
            'removalDate',
            'gtmNumber',
            'remarks',
            'action'
          ]"
          :data="stationCashCollectionForm.outgoing_cashBoxList"
        >
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
          <template v-slot:isFaulty="slotScoped">
            {{slotScoped.data ? 'Yes' : 'No'}}
          </template>
          <template v-slot:removalDate="slotScoped">
            {{slotScoped.data ? displayUtcDate(slotScoped.data) : '-'}}
          </template>
          <template v-slot:gtmNumber="slotScoped">
            {{slotScoped.data ? slotScoped.data : '-'}}
          </template>
          <template v-slot:action="slotScoped">
            <asom-button outline size="sm" variant="error" text="Remove" @click="removeCashBox(slotScoped.rowData)" />
          </template>
        </asom-client-table>
      </asom-card>
    </article>
    <article>
      <asom-card title=" ">
        <template v-slot:header>
          <div class="flex justify-between content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Non Cash Item</h3>
            <add-outgoing-non-cash />
          </div>
        </template>
        <p class="text-left" v-if="stationCashCollectionForm.outgoing_nonCashList.length === 0">
          No non cash item added
        </p>
        <asom-client-table
          v-else
          :filterable="false"
          :pagination="false"
          :columns="[
            'no',
            'description',
            'serialNumber',
            'remarks',
            'action'
          ]"
          :data="stationCashCollectionForm.outgoing_nonCashList"
        >
          <template v-slot:header_serialNumber>Serial Number</template>
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
          <template v-slot:action="slotScoped">
            <asom-button outline size="sm" variant="error" text="Remove" @click="removeNonCashItem(slotScoped.rowData)" />
          </template>
        </asom-client-table>
        <div v-if="!isOnline" class="text-sm mt-4">
          Cannot upload files under offline mode. Please add supporting documents later.
        </div>
        <asom-form-field label="Supporting Documents" 
          v-else-if="stationCashCollectionForm.outgoing_nonCashList.length > 0">
          <asom-upload 
            :modelValue="stationCashCollectionForm.documentList"
            :category="attachmentCategories.CASH_MANAGEMENT"
            @update:modelValue="setDocumentList"
          />
        </asom-form-field>
      </asom-card>
    </article>
    <article class="flex justify-between">
      <asom-button outline
        text="Back"
        variant="secondary"
        @click="onBackClicked"
      />
      <asom-button outline
        text="Next"
        variant="secondary"
        @click="onNextClicked"
      />
    </article>
  </div>
</template>
<script>
import { inject } from 'vue';
import AddOutgoingCashBag from "./AddOutgoingCashBag.vue"
import AddOutgoingCashBox from "./AddOutgoingCashBox.vue"
import AddOutgoingNonCash from "./AddOutgoingNonCash.vue"
import { mapGetters } from 'vuex';
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  components: {
    AddOutgoingCashBag,
    AddOutgoingCashBox,
    AddOutgoingNonCash,
  },
  setup() {
    const currentStep = inject('currentStep');
    const setCurrentStep = inject('setCurrentStep');
    const stationCashCollectionForm = inject('stationCashCollectionForm');
    const setOutgoingCashBagList = inject('setOutgoingCashBagList');
    const setOutgoingCashBoxList = inject('setOutgoingCashBoxList');
    const setOutgoingNonCashList = inject('setOutgoingNonCashList');
    const setDocumentList = inject('setDocumentList');

    return {
      currentStep,
      setCurrentStep,
      stationCashCollectionForm,
      setOutgoingCashBagList,
      setOutgoingCashBoxList,
      setOutgoingNonCashList,
      setDocumentList,
    }
  },
  computed: {
    ...mapGetters({
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    displayUtcDate,
    onBackClicked() {
      this.setCurrentStep(1)
    },
    onNextClicked() {
      this.setCurrentStep(3);
    },
    removeCashBag({ cashBagSerialNumber }) {
      const newList = this.stationCashCollectionForm.outgoing_cashBagList
        .filter(item => item.cashBagSerialNumber != cashBagSerialNumber);
      this.setOutgoingCashBagList(newList);
    },
    removeCashBox({ cashBoxSerialNumber }) {
      const newList = this.stationCashCollectionForm.outgoing_cashBoxList
        .filter(item => item.cashBoxSerialNumber != cashBoxSerialNumber);
      this.setOutgoingCashBoxList(newList);
    },
    removeNonCashItem({ serialNumber }) {
      const newList = this.stationCashCollectionForm.outgoing_nonCashList
        .filter(item => item.serialNumber != serialNumber);
      this.setOutgoingNonCashList(newList);
    },
  }
}
</script>