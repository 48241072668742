<template>
  <div class="flex justify-end items-center">
    <asom-button
      outline
      size="sm"
      text="Add Non Cash Item"
      variant="primary"
      @click="toggleModal(true)"
    />
    <asom-modal
      v-model="showModal"
      title="Add Outgoing Non Cash Item"
      size="md"
      :dismissible="false"
    >
      <asom-alert v-if="error" variant="error" :errorMessage="error" />
      <div>
        <asom-form-field
          label="Serial Number"
          required
          :state="inputStates('outgoingNonCash.serialNumber')"
        >
          <scan-code
            v-model="outgoingNonCash.serialNumber"
            :state="inputStates('outgoingNonCash.serialNumber')"
          />
        </asom-form-field>
        <asom-form-field label="Description">
          <asom-input-textarea v-model="outgoingNonCash.description" />
        </asom-form-field>
        <asom-form-field label="Remarks">
          <asom-input-textarea v-model="outgoingNonCash.remarks" />
        </asom-form-field>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button @click="onSubmit" text="Add" />
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>
<script>
import { inject} from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import ScanCode from "../../../_ScanCode.vue";

export default {
  components: {
    ScanCode,
  },
  mixins: [inputStates],
  setup() {
    const stationCashCollectionForm = inject('stationCashCollectionForm');
    const setOutgoingNonCashList = inject('setOutgoingNonCashList');

    return {
      v$: useVuelidate(),
      stationCashCollectionForm,
      setOutgoingNonCashList,
    };
  },
  data() {
    return {
      outgoingNonCash: {
        serialNumber: "",
        description: "",
        remarks: "",
      },
      error: null,
      showModal: false,
    };
  },
  validations() {
    return {
      outgoingNonCash: {
        serialNumber: {
          required,
        },
      },
    };
  },
  methods: {
    toggleModal(value) {
      this.showModal = value;
      if (!value) {
        this.outgoingNonCash.serialNumber = "";
        this.outgoingNonCash.description = "";
        this.outgoingNonCash.remarks = "";
        this.error = null;
        this.v$.$reset();
      }
    },
    onSubmit() {
      this.error = null;
      this.v$.$touch();
      if (this.v$.outgoingNonCash.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      } else {
        const isExisted = this.stationCashCollectionForm.outgoing_nonCashList
          .some(item => item.serialNumber === this.outgoingNonCash.serialNumber);
        if (isExisted) {
          this.error = `Non Cash Item ${this.outgoingNonCash.serialNumber} has been added`;
        } else {
          const newList = [
            ...this.stationCashCollectionForm.outgoing_nonCashList,
            {
              serialNumber: this.outgoingNonCash.serialNumber.trim(),
              description: this.outgoingNonCash.description,
              remarks: this.outgoingNonCash.remarks,
            },
          ]
          this.setOutgoingNonCashList(newList);
          this.toggleModal(false);
        }
      }
    },
  },
};
</script>
