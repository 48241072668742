<template>
  <section class="space-y-10">
    <offline-action-alert v-if="!isOnline" />
    <QuickInfo/>
    <IncomingForm v-if="currentStep === 1" />
    <OutgoingForm v-else-if="currentStep === 2" />
    <Confirmation v-else />
  </section>
</template>
<script>
import get from 'lodash.get';
import flatten from "lodash.flatten";
import { ref, reactive, readonly, provide } from 'vue';
import { useRoute } from "vue-router";
import { mapGetters, useStore } from 'vuex';
import QuickInfo from "../../../_QuickInfo.vue";
import IncomingForm from './IncomingForm.vue';
import OutgoingForm from './OutgoingForm.vue';
import Confirmation from './Confirmation.vue';
import OfflineActionAlert from "@/layout/OfflineActionAlert"
import isEmpty from 'lodash.isempty';

const SCCF_STEPS = {
  'Incoming': 1,
  'Outgoing': 2,
  'Confirmation': 3,
}

export default {
  components: {
    QuickInfo,
    IncomingForm,
    OutgoingForm,
    Confirmation,
    OfflineActionAlert,
  },
  setup() {
    const route = useRoute();
    const offlineId = get(route, 'query.offlineId');

    const isOfflineData = ref(!!offlineId);
    const isOfflineDataEditable = ref(true);

    const currentStep = ref(SCCF_STEPS.Incoming);
    const stationCashCollectionForm = reactive({
      skipCashOrder: false,
      duffleBagSerialNumber: "",
      isDuffleBagRejected: false,
      duffleBagRejectionRemarks: "",
      cashOrderIdList: [],
      incoming_cashBagList: [],
      incoming_receivedCoinTubeList: {},
      incoming_rejectedCoinTubeList: {},
      outgoing_cashBagList: [],
      outgoing_cashBoxList: [],
      outgoing_nonCashList: [],
      documentList: [],
      officerSignatureId: null,
      officerSignatureData: null,
      contractorName: '',
      contractorSignatureId: null,
      contractorSignatureData: null,
      attachments: [],
    });

    const setCurrentStep = (newStep) => {
      if (currentStep.value != newStep && Object.values(SCCF_STEPS).includes(newStep)) {
        currentStep.value = newStep;
      }
    }

    const setSkipCashOrder = (newValue) => {
      stationCashCollectionForm.skipCashOrder = newValue;
    }

    const setDuffleBagSerialNumber = (newValue) => {
      stationCashCollectionForm.duffleBagSerialNumber = newValue;
    }

    const setIsDuffleBagRejected = (newValue) => {
      stationCashCollectionForm.isDuffleBagRejected = !!newValue;
    }

    const setDuffleBagRejectionRemarks = (newValue) => {
      stationCashCollectionForm.duffleBagRejectionRemarks = newValue;
    }

    const setCashOrderIdList = (newList = []) => {
      let removedIdList = readonly(stationCashCollectionForm.cashOrderIdList);
      removedIdList.filter(id => !newList.includes(id));
      stationCashCollectionForm.cashOrderIdList = newList;
      stationCashCollectionForm.incoming_cashBagList = stationCashCollectionForm.incoming_cashBagList
        .filter(item => newList.includes(item.cashOrderId));
      const {
        incoming_receivedCoinTubeList,
        incoming_rejectedCoinTubeList
      } = stationCashCollectionForm;
      removedIdList.forEach(id => {
        delete(incoming_receivedCoinTubeList, id)
        delete(incoming_rejectedCoinTubeList, id)
      });
      stationCashCollectionForm['incoming_receivedCoinTubeList'] = {...incoming_receivedCoinTubeList};
      stationCashCollectionForm['incoming_rejectedCoinTubeList'] = {...incoming_rejectedCoinTubeList};

    }

    const setIncomingCashBagList = (newList = []) => {
      stationCashCollectionForm.incoming_cashBagList = newList;
    }

    const setIncomingReceivedCoinTubeList = (newList = {}) => {
      stationCashCollectionForm.incoming_receivedCoinTubeList = newList;
    }
    
    const setIncomingRejectedCoinTubeList = (newList = {}) => {
      stationCashCollectionForm.incoming_rejectedCoinTubeList = newList;
    }
    
    const setOutgoingCashBagList = (newList = []) => {
      stationCashCollectionForm.outgoing_cashBagList = newList;
    }

    const setOutgoingCashBoxList = (newList = []) => {
      stationCashCollectionForm.outgoing_cashBoxList = newList;
    }

    const setOutgoingNonCashList = (newList = []) => {
      stationCashCollectionForm.outgoing_nonCashList = newList;
    }

    const setDocumentList = (newList = []) => {
      stationCashCollectionForm.documentList = newList;
    }

    const setOfficerSignatureData = (data) => {
      stationCashCollectionForm.officerSignatureData = data;
    }

    const setOfficerSignatureId = (id) => {
      stationCashCollectionForm.officerSignatureId = id;
    }

    const setContractorName = (newValue) => {
      stationCashCollectionForm.contractorName = newValue;
    }

    const setContractorSignatureData = (data) => {
      stationCashCollectionForm.contractorSignatureData = data;
    }

    const setContractorSignatureId = (id) => {
      stationCashCollectionForm.contractorSignatureId = id;
    }

    const setOffLineAttachments = (data) => {
      stationCashCollectionForm.attachments = data;
    }

    provide('isOfflineData', isOfflineData);
    provide('isOfflineDataEditable', isOfflineDataEditable);

    provide('currentStep', currentStep);
    provide('setCurrentStep', setCurrentStep);

    provide('stationCashCollectionForm', stationCashCollectionForm);
    provide('setSkipCashOrder', setSkipCashOrder);
    provide('setDuffleBagSerialNumber', setDuffleBagSerialNumber);
    provide('setIsDuffleBagRejected', setIsDuffleBagRejected);
    provide('setDuffleBagRejectionRemarks', setDuffleBagRejectionRemarks);
    provide('setCashOrderIdList', setCashOrderIdList);
    provide('setIncomingCashBagList', setIncomingCashBagList);
    provide('setIncomingReceivedCoinTubeList', setIncomingReceivedCoinTubeList);
    provide('setIncomingRejectedCoinTubeList', setIncomingRejectedCoinTubeList);
    provide('setOutgoingCashBagList', setOutgoingCashBagList);
    provide('setOutgoingCashBoxList', setOutgoingCashBoxList);
    provide('setOutgoingNonCashList', setOutgoingNonCashList);
    provide('setDocumentList', setDocumentList);
    provide('setOfficerSignatureData', setOfficerSignatureData);
    provide('setOfficerSignatureId', setOfficerSignatureId);
    provide('setContractorName', setContractorName);
    provide('setContractorSignatureData', setContractorSignatureData);
    provide('setContractorSignatureId', setContractorSignatureId);
    provide('setOffLineAttachments', setOffLineAttachments);
    if (isOfflineData.value) {
      const store = useStore();
      const offlineData = get(store, `state.offlineData.requestData.${offlineId}.data`, null);
      setSkipCashOrder(get(offlineData, 'skipIncoming', false));
      setDuffleBagSerialNumber(get(offlineData, 'incoming.duffleBagSerialNumber'));
      setIsDuffleBagRejected(get(offlineData, 'incoming.isDuffleBagRejected'));
      setDuffleBagRejectionRemarks(get(offlineData, 'incoming.duffleBagRejectionRemarks'));
      var cashOrders = get(offlineData, 'incoming.cashOrders', []);
      setCashOrderIdList(cashOrders
        .map(({ cashOrderId }) => cashOrderId));
      setIncomingCashBagList(flatten(cashOrders
        .map(({ cashBags }) => cashBags)));
      setIncomingReceivedCoinTubeList(cashOrders
        .filter(({ receivedCoinTube }) => !!receivedCoinTube)
        .map(({ receivedCoinTube }) => receivedCoinTube));
      setIncomingRejectedCoinTubeList(cashOrders
        .filter(({ rejectedCoinTube }) => !!rejectedCoinTube)
        .map(({ rejectedCoinTube }) => rejectedCoinTube));
      setOutgoingCashBagList(get(offlineData, 'outgoing.cashBags'));
      setOutgoingCashBoxList(get(offlineData, 'outgoing.cashBoxes'));
      setOutgoingNonCashList(get(offlineData, 'outgoing.nonCashItems'));
      setOfficerSignatureData(get(offlineData, 'officerSignature'));
      setContractorName(get(offlineData, 'contractorName'));
      setContractorSignatureData(get(offlineData, 'contractorSignature'));
      setCurrentStep(SCCF_STEPS.Confirmation);
      setOffLineAttachments(get(offlineData, 'attachments'));
      isOfflineDataEditable.value = isEmpty(stationCashCollectionForm, 'officerSignatureData') ||
        isEmpty(stationCashCollectionForm, 'contractorSignatureData')
    }

    return {
      currentStep,
    }
  },
  mounted() {
    this.$store.dispatch('cashManagement/cashOrders/loadCashOrderList');
  },
  computed: {
    ...mapGetters({
      isOnline: "apiStatus/isCMModuleOnline",
    })
  },
  watch: {
    currentStep() {
      this.$scrollTop();
    }
  }
}
</script>