<template>
  <div class="flex justify-end items-center">
    <asom-button
      outline
      size="sm"
      text="Add TE Bag"
      variant="primary"
      @click="toggleModal(true)"
    />
    <asom-modal
      v-model="showModal"
      title="Add Outgoing TE Bag"
      size="md"
      :dismissible="false"
    >
      <asom-alert v-if="error" variant="error" :errorMessage="error" />
      <div>
        <asom-form-field
          label="TE Bag Serial Number"
          required
          :state="inputStates('outgoingCashBag.cashBagSerialNumber')"
        >
          <scan-code
            v-model="outgoingCashBag.cashBagSerialNumber"
            :state="inputStates('outgoingCashBag.cashBagSerialNumber')"
          />
        </asom-form-field>
        <asom-form-field label="Remarks">
          <asom-input-textarea v-model="outgoingCashBag.remarks" />
        </asom-form-field>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button @click="onSubmit" text="Add" />
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>
<script>
import { inject } from 'vue'
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import ScanCode from "../../../_ScanCode.vue";

export default {
  components: {
    ScanCode,
  },
  mixins: [inputStates],
  setup() {
    const stationCashCollectionForm = inject('stationCashCollectionForm');
    const setOutgoingCashBagList = inject('setOutgoingCashBagList');

    return {
      v$: useVuelidate(),
      stationCashCollectionForm,
      setOutgoingCashBagList,
    };
  },
  data() {
    return {
      outgoingCashBag: {
        cashBagSerialNumber: "",
        remarks: "",
      },
      error: null,
      showModal: false,
    };
  },
  validations() {
    return {
      outgoingCashBag: {
        cashBagSerialNumber: {
          required,
        },
      },
    };
  },
  methods: {
    toggleModal(value) {
      this.showModal = value;
      if (!value) {
        this.outgoingCashBag.cashBagSerialNumber = "";
        this.outgoingCashBag.remarks = "";
        this.error = null;
        this.v$.$reset();
      }
    },
    onSubmit() {
      this.error = null;
      this.v$.$touch();
      if (this.v$.outgoingCashBag.$invalid) {
        this.error = "Please complete all required fields";
      } else {
        const isExisted = this.stationCashCollectionForm.outgoing_cashBagList
          .some(item => item.cashBagSerialNumber === this.outgoingCashBag.cashBagSerialNumber)
        if (isExisted) {
          this.error = `Cash Bag ${this.outgoingCashBag.cashBagSerialNumber} has been added`;
        } else {
          const newList = [
            ...this.stationCashCollectionForm.outgoing_cashBagList,
            {
              cashBagSerialNumber: this.outgoingCashBag.cashBagSerialNumber.trim(),
              remarks: this.outgoingCashBag.remarks,
            }
          ]
          this.setOutgoingCashBagList(newList);
          this.toggleModal(false);
        }
      }
    },
  },
};
</script>
