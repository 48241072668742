<template>
  <div class="space-y-4">
    <h2 class="text-xl font-semibold">Incoming SCCF List</h2>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card title="Duffle Bag">
        <asom-form-field
          :label="'Duffle Bag Serial Number'"
          :state="inputStates('incomingData.duffleBagSerialNumber')"
          error="Duffle Bag Serial Number is required"
          required
        >
          <div class="flex" >
            <scan-code
              :modelValue="incomingData.duffleBagSerialNumber"
              @update:modelValue="setDuffleBagSerialNumber"
              :state="inputStates('incomingData.duffleBagSerialNumber')"
              
            />
          </div>
        </asom-form-field>
        <asom-input-checkbox
          class="mt-4"
          :modelValue="incomingData.isDuffleBagRejected"
          @update:modelValue="setIsDuffleBagRejected"
          v-model="incomingData.isDuffleBagRejected"
          :label="'Is Duffle Bag Rejected'"
          
        />
        <asom-form-field
          :label="'Rejection Remarks'"
          v-if="incomingData.isDuffleBagRejected"
          :state="inputStates('incomingData.duffleBagRejectionRemarks')"
          required
        >
          <asom-input-textarea
            :modelValue="incomingData.duffleBagRejectionRemarks"
            @update:modelValue="setDuffleBagRejectionRemarks"
            :state="inputStates('incomingData.duffleBagRejectionRemarks')"
            
          />
        </asom-form-field>
      </asom-card>
    </article>
    <article class="space-y-4">
      <cash-order-card  v-for="id in incomingData.cashOrderIdList" :key="id" :cashOrderId="id" />
      <asom-card v-if="filteredCashOrderOptions.length > 0" title="New Cash Order">
        <asom-form-field
          :label="'Select a Cash Order'"
          :state="inputStates('incomingData.cashOrderIdList')"
          error="Cash Order is required"
          required
        >
          <asom-input-radio-group
            class="mt-3"
            :key="selectedId"
            :state="inputStates('cashOrderId')"
            :modelValue="selectedId"
            @update:modelValue="addCashOrder"
            :options="filteredCashOrderOptions"
            
          />
        </asom-form-field>
      </asom-card>
      <p class="text-center text-gray-500" v-if="cashOrderOptions.length === 0">No Cash Order for selection</p> 
    </article>
    <article class="text-right space-x-4">
      <asom-button outline
        text="Skip Cash Order"
        variant="primary"
        @click="setShowSkipModal(true)"
      />
      <asom-button outline
        text="Next"
        variant="secondary"
        @click="onNextClicked"
      />
    </article>
    <asom-modal title="Confirmation" v-model="showSkipModal">
      <p class="text-sm text-gray-500">
        You are skipping to add information of cash order and incoming items. Do you want to continue?
      </p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onSkip"
          text="Confirm"
        />
        <asom-button
          @click="setShowSkipModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>
<script>
import { nanoid } from 'nanoid';
import { inject, ref } from 'vue';
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import ScanCode from "../../../_ScanCode.vue";
import CashOrderCard from './CashOrderCard.vue'

export default {
  components: {
    ScanCode,
    CashOrderCard,
  },
  mixins: [inputStates],
  setup() {
    const selectedId = ref(null);
    const showSkipModal = ref(false);
    const currentStep = inject('currentStep');
    const setCurrentStep = inject('setCurrentStep');
    const error = ref('');

    const setShowSkipModal = (newValue) => {
      showSkipModal.value = !!newValue
    }

    const setError = (newValue) => {
      error.value = newValue
    };

    const setSelectedId = id => selectedId.value = id;
    const incomingData = inject('stationCashCollectionForm');
    const setSkipCashOrder = inject('setSkipCashOrder');
    const setCashOrderIdList = inject('setCashOrderIdList');
    const setIncomingReceivedCoinTubeList = inject('setIncomingReceivedCoinTubeList');
    const setIncomingRejectedCoinTubeList = inject('setIncomingRejectedCoinTubeList');
    const setDuffleBagSerialNumber = inject('setDuffleBagSerialNumber');
    const setIsDuffleBagRejected = inject('setIsDuffleBagRejected');
    const setDuffleBagRejectionRemarks = inject('setDuffleBagRejectionRemarks');

    return {
      v$: useVuelidate(),
      currentStep,
      setCurrentStep,
      error,
      setError,
      showSkipModal,
      setShowSkipModal,
      selectedId,
      setSelectedId,
      incomingData,
      setSkipCashOrder,
      setCashOrderIdList,
      setIncomingReceivedCoinTubeList,
      setIncomingRejectedCoinTubeList,
      setDuffleBagSerialNumber,
      setIsDuffleBagRejected,
      setDuffleBagRejectionRemarks,
    }
  },
  validations() {
    let validations = {
      incomingData: {
        duffleBagSerialNumber: {
          required,
        },
        cashOrderIdList: {
          required,
          minLength: minLength(1),
          $each: {
            cashOrderId: {
              required,
            },
          }
        }
      }
    }
    if (this.incomingData.isDuffleBagRejected) {
      validations.incomingData.duffleBagRejectionRemarks = {
        required,
      }
    } 
    return validations;
  },
  computed: {
    ...mapGetters({
      cashOrderOptions: 'cashManagement/cashOrders/getListFromContractorAsOptions',
    }),
    filteredCashOrderOptions() {
      return this.cashOrderOptions.filter(option => !this.incomingData.cashOrderIdList.includes(option.value));
    },
  },
  methods: {
    async addCashOrder(newCashOrderId) {
      this.setSelectedId(nanoid());
      if (!this.incomingData.cashOrderIdList.includes(newCashOrderId)) {
        this.setCashOrderIdList([
          ...this.incomingData.cashOrderIdList,
          newCashOrderId,
        ]);
        this.setIncomingReceivedCoinTubeList({
          ...this.incomingData.incoming_receivedCoinTubeList,
          cashOrderId: {
            noOf10CentTubes: 0,
            noOf20CentTubes: 0,
            noOf50CentTubes: 0,
            noOf1DollarTubes: 0,
            remarks: '',
          }
        });
        this.setIncomingRejectedCoinTubeList({
          ...this.incomingData.incoming_rejectedCoinTubeList,
          cashOrderId: {
            hasRejectedCoinTube: false,
            noOf10CentTubes: 0,
            noOf20CentTubes: 0,
            noOf50CentTubes: 0,
            noOf1DollarTubes: 0,
            remarks: '',
          }
        })
      }
    },
    onNextClicked() {
      this.setError('');
      this.v$.$reset();
      this.v$.$touch();
      const invalid = this.v$.incomingData.$invalid;
      if (invalid) {
        this.setError('Please complete all required fields');
        this.$scrollTop();
      } else {
        this.setSkipCashOrder(false);
        this.v$.$reset();
        this.setCurrentStep(2);
      }
    },
    onSkip() {
      this.setSkipCashOrder(true);
      this.setError('');
      this.v$.$reset();
      this.setShowSkipModal(false);
      this.setCurrentStep(2);
    }
  }
}
</script>