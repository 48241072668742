<template>
  <asom-card :title="cardTitle">
    <template v-slot:header>
      <div class="flex justify-between">
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ cardTitle }}</h3>
        <button v-if="allowAction" type="button" @click="removeCashOrder" class="AsomAlert__Close">
          <span class="sr-only">remove</span>
          <!-- Heroicon name: solid/x -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </template>
    <div class="space-y-4">
      <asom-grid :sm="1" :md="3" v-if="cashOrderDetails != null">
        <asom-form-field label="Order Type">
          <span class="mt-3">{{cashOrderType}}</span>
        </asom-form-field>
        <asom-form-field label="Order Amount">
          <span class="mt-3">${{cashOrderDetails.expectedAmount}}</span>
        </asom-form-field>
      </asom-grid>
      <asom-alert 
        v-if="incomingData.isDuffleBagRejected"
        variant="info" 
        :title="rejectedDuffleBagMessage"
      />
      <template v-else>
        <article v-if="cashOrderTypeEnum === 1" class="space-y-4">
          <div class="flex justify-between content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Incoming Cash Bag</h3>
            <add-incoming-cash-bag v-if="allowAction" :cashOrderId="cashOrderId" />
          </div>
          <p class="text-left" v-if="incomingCertisBagList.length === 0">
            No cash bag added
          </p>
          <asom-client-table
            v-else
            :filterable="false"
            :pagination="false"
            :columns="[
              'no',
              'cashBagSerialNumber',
              'status',
              'remarks',
              'action'
            ]"
            :data="incomingCertisBagList"
          >
            <template v-slot:header_cashBagSerialNumber>Cash Bag Serial Number</template>
            <template v-slot:no="slotScoped">
              {{slotScoped.rowIndex + 1}}
            </template>
            <template v-slot:status="slotScoped">
              {{slotScoped.rowData.isRejected ? 'Rejected' : 'Accepted'}}
            </template>
            <template v-slot:action="slotScoped">
              <asom-button v-if="allowAction" outline size="sm" variant="error" text="Remove" @click="removeBag(slotScoped.rowData)" />
            </template>
          </asom-client-table>
        </article>
        <article  v-if="cashOrderTypeEnum === 2" class="space-y-4">
          <denominations
            :label="'Coin Tubes Received'"
            show-remarks
            v-model:noOf10CentTubes="received_noOf10CentTubes"
            v-model:noOf20CentTubes="received_noOf20CentTubes"
            v-model:noOf50CentTubes="received_noOf50CentTubes"
            v-model:noOf1DollarTubes="received_noOf1DollarTubes"
            v-model:remarks="received_coinTubeRemarks"
            :readonly="!allowAction"
          />
          <asom-input-checkbox
            class="mt-3"
            v-model="hasRejectedCoinTubes"
            label="Has rejected coin tubes?"
          />
          <denominations
            v-if="hasRejectedCoinTubes"
            :label="'Coin Tubes Rejected'"
            show-remarks
            v-model:noOf10CentTubes="rejected_noOf10CentTubes"
            v-model:noOf20CentTubes="rejected_noOf20CentTubes"
            v-model:noOf50CentTubes="rejected_noOf50CentTubes"
            v-model:noOf1DollarTubes="rejected_noOf1DollarTubes"
            v-model:remarks="rejected_coinTubeRemarks"
            :readonly="!allowAction"
          />
        </article>
      </template>
    </div>
  </asom-card>
</template>
<script>
import get from 'lodash.get'
import { inject, computed } from 'vue'
import Denominations from "../../../_Denominations.vue"
import AddIncomingCashBag from "./AddIncomingCashBag.vue"

export default {
  components: {
    Denominations,
    AddIncomingCashBag
  },
  props: {
    cashOrderId: {
      type: String,
      required: true,
    }
  },
  setup() {
    const incomingData = inject('stationCashCollectionForm');
    const setCashOrderIdList = inject('setCashOrderIdList');
    const setIncomingCashBagList = inject('setIncomingCashBagList');
    const setIncomingReceivedCoinTubeList = inject('setIncomingReceivedCoinTubeList');
    const setIncomingRejectedCoinTubeList = inject('setIncomingRejectedCoinTubeList');
    const isOfflineData = inject('isOfflineData');
    const isOfflineDataEditable = inject('isOfflineDataEditable');

    const allowAction = computed(() => !isOfflineData || isOfflineDataEditable)

    return {
      incomingData,
      setCashOrderIdList,
      setIncomingCashBagList,
      setIncomingReceivedCoinTubeList,
      setIncomingRejectedCoinTubeList,
      allowAction,
    }
  },
  computed: {
    cashOrderDetails() {
      return this.$store.getters['cashManagement/cashOrders/getById'](this.cashOrderId);
    },
    cardTitle() {
      return 'Cash Order ' + get(this.cashOrderDetails, 'referenceNo')
    },
    cashOrderTypeEnum() {
      return get(this.cashOrderDetails, 'cashOrderTypeEnum');
    },
    cashOrderType() {
      switch (this.cashOrderTypeEnum) {
        case 1:
          return 'Manual Change';
        case 2:
          return 'GTM/TUK Coin Tube';
        default:
          return 'Unknown';
      }
    },
    rejectedDuffleBagMessage() {
      switch (this.cashOrderTypeEnum) {
        case 1:
          return 'No incoming cash bag due to rejected duffle bag';
        case 2:
          return 'No incoming coin tube due to rejected duffle bag';
        default:
          return 'No item added due torejected duffle bag';
      }
    },
    incomingCertisBagList() {
      return this.incomingData.incoming_cashBagList.filter(bag => bag.cashOrderId == this.cashOrderId);
    },
    receivedCoinTube() {
      return get(this.incomingData.incoming_receivedCoinTubeList, this.cashOrderId);
    },
    rejectedCoinTube() {
      return get(this.incomingData.incoming_rejectedCoinTubeList, this.cashOrderId);
    },
    received_noOf10CentTubes: {
      get() {
        return get(this.receivedCoinTube, 'noOf10CentTubes', 0);
      },
      set(newValue) {
        this.updateReceivedCashOrder('noOf10CentTubes', newValue);
      }
    },
    received_noOf20CentTubes: {
      get() {
        return get(this.receivedCoinTube, 'noOf20CentTubes', 0);
      },
      set(newValue) {
        this.updateReceivedCashOrder('noOf20CentTubes', newValue);
      }
    },
    received_noOf50CentTubes: {
      get() {
        return get(this.receivedCoinTube, 'noOf50CentTubes', 0);
      },
      set(newValue) {
        this.updateReceivedCashOrder('noOf50CentTubes', newValue);
      }
    },
    received_noOf1DollarTubes: {
      get() {
        return get(this.receivedCoinTube, 'noOf1DollarTubes', 0);
      },
      set(newValue) {
        this.updateReceivedCashOrder('noOf1DollarTubes', newValue);
      }
    },
    received_coinTubeRemarks: {
      get() {
        return get(this.receivedCoinTube, 'remarks', '');
      },
      set(newValue) {
        this.updateReceivedCashOrder('remarks', newValue);
      }
    },
    hasRejectedCoinTubes: {
      get() {
        return get(this.rejectedCoinTube, 'hasRejectedCoinTube', false);
      },
      set(newValue) {
        this.updateRejectedCashOrder('hasRejectedCoinTube', newValue);
      }
    },
    rejected_noOf10CentTubes: {
      get() {
        return get(this.rejectedCoinTube, 'noOf10CentTubes', 0);
      },
      set(newValue) {
        this.updateRejectedCashOrder('noOf10CentTubes', newValue);
      }
    },
    rejected_noOf20CentTubes: {
      get() {
        return get(this.rejectedCoinTube, 'noOf20CentTubes', 0);
      },
      set(newValue) {
        this.updateRejectedCashOrder('noOf20CentTubes', newValue);
      }
    },
    rejected_noOf50CentTubes: {
      get() {
        return get(this.rejectedCoinTube, 'noOf50CentTubes', 0);
      },
      set(newValue) {
        this.updateRejectedCashOrder('noOf50CentTubes', newValue);
      }
    },
    rejected_noOf1DollarTubes: {
      get() {
        return get(this.rejectedCoinTube, 'noOf1DollarTubes', 0);
      },
      set(newValue) {
        this.updateRejectedCashOrder('noOf1DollarTubes', newValue);
      }
    },
    rejected_coinTubeRemarks: {
      get() {
        return get(this.rejectedCoinTube, 'remarks', '');
      },
      set(newValue) {
        this.updateRejectedCashOrder('remarks', newValue);
      }
    },
  },
  methods: {
    updateReceivedCashOrder(key, value) {
      this.setIncomingReceivedCoinTubeList({
          ...this.incomingData.incoming_receivedCoinTubeList,
          [this.cashOrderId]: {
            ...this.receivedCoinTube,
            [key]: value,
          }
        })
    },
    updateRejectedCashOrder(key, value) {
      this.setIncomingRejectedCoinTubeList({
          ...this.incomingData.incoming_rejectedCoinTubeList,
          [this.cashOrderId]: {
            ...this.rejectedCoinTube,
            [key]: value,
          }
        })
    },
    removeBag({ cashBagSerialNumber }) {
      const newList = this.incomingData.incoming_cashBagList.filter(item => item.cashBagSerialNumber != cashBagSerialNumber);
      this.setIncomingCashBagList(newList);
    },
    removeCashOrder() {
      const newList = this.incomingData.cashOrderIdList.filter(id => id !== this.cashOrderId);
      this.setCashOrderIdList(newList);
    }
  }
}
</script>